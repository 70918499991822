<template>
  <div class="container w-full mx-auto mb-10 px-4 md:px-0">
    <ViewTitle :title="$t('cabinet.feedbacks.title')" />

    <div v-for="feedback in feedbacks" :key="feedback.id">
      <Feedback :feedback="feedback" :showUser="false" />
    </div>

    <div class="text-center py-16" v-if="feedbacks.length <= 0">
      <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
      <p class="text-gray-400">
        {{ $t("cabinet.feedbacks.no_feedbacks") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Feedback from "@/components/Feedback";
import { InboxIcon } from "vue-feather-icons";
import ViewTitle from "@/components/ViewTitle.vue";

export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  components: {
    Feedback,
    InboxIcon,
    ViewTitle,
  },
  created() {
    this.getUserFeedbacks();
  },
  watch: {
    $route: ["getUserFeedbacks"],
  },
  computed: mapState({
    feedbacks: (state) => state.cabinet.feedbacks.list,
  }),
  methods: {
    getUserFeedbacks() {
      this.$store.dispatch("cabinet/feedbacks/getUserFeedbacks", 1);
    },
  },
};
</script>
